import Vue from 'vue'
import App from './App.vue'
import '@fortawesome/fontawesome-svg-core/styles.css'

/* 导入 Font Awesome 核心 */
import { library } from '@fortawesome/fontawesome-svg-core'
/* 导入图标组件 */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* 导入你需要的图标 */
import { faApple, faAndroid, faWindows } from '@fortawesome/free-brands-svg-icons'
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons'

/* 将图标添加到库中 */
library.add(faApple, faAndroid, faWindows, faInfoCircle, faTimes)

/* 注册全局组件 */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
